export const products = [
  {
    id: 1,
    img: "cover4.png",
    link: "https://meraj-fit-club.pages.dev/"
  },
  {
    id: 2,
    img: "cover6.png",
    link: "https://meraj-space-portfolio.pages.dev/"
  },
  {
    id: 3,
    img: "cover5.png",
    link: "#",
  },
  {
    id: 4,
    img: "cover3.png",
    link: "#",
  },
  {
    id: 5,
    img: "cover8.png",
    link: "#",
  },
  {
    id: 6,
    img: "cover9.png",
    link: "#",
  },
  {
    id: 7,
    img: "cover7.png",
    link: "#",
  },
  {
    id: 8,
    img: "cover1.png",
    link: "#",
  },
  {
    id: 9,
    img: "cover2.png",
    link: "#",
  },
];
